import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  steps: string[]
  activeStep: number;
  useSameInformation: boolean;
  homeAddress: {
    stateName: string, StreetAddress2: string, cityName: string, zipCode: number, homeAddress: string
  },
  BillingAddress: {
    stateName: string, StreetAddress2: string, cityName: string, zipCode: number, homeAddress: string
  },
  cardDetails: { cardNumber: string, nameOnCard: string, expirationCode: string, securityCode: number },
  openDetailedHomeAddress: boolean;
  openDetailedBillingAddress: boolean;
  ExpiryDateTrue: boolean,
  checkBox: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Splitpayments2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      homeAddress: { stateName: "", StreetAddress2: "", cityName: "", zipCode: 0, homeAddress: "" },
      cardDetails: { cardNumber: "", nameOnCard: "", expirationCode: "", securityCode: 0 },
      openDetailedHomeAddress: false,
      BillingAddress: { stateName: "", StreetAddress2: "", cityName: "", zipCode: 0, homeAddress: "" },
      openDetailedBillingAddress: false,
      useSameInformation: false,
      checkBox: false,
      ExpiryDateTrue: false,
      steps: ["About You", "Verify Insurance", "Choose Provider"],
      activeStep: 3,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleCloseHomeAddress = () => { this.setState({ openDetailedHomeAddress: false }) };
  handleOpenHomeAddress = () => { this.setState({ openDetailedHomeAddress: true }) };
  handleHomeAddress = (e: any, stateName: string) => {
    this.setState(prevState => ({
      homeAddress: {
        ...prevState.homeAddress,
        [stateName]: e.target.value
      },
    }));

  };

  handleZipCodeForHomeAddress = (e: any, stateName: string) => {
    const { value } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      this.setState(prevState => ({
        homeAddress: {
          ...prevState.homeAddress,
          [stateName]: parseInt(e.target.value),
        },
      }), () => { this.copyDataFromHomeDetails() });
    }
  };
  handleInputChange = (e: any, state: string) => {
    let value = e.target.value;
    value = value.replace(/\D/g, '');
    if (value.length > 2) {
      value = value.slice(0, 2) + ' / ' + value.slice(2, 4);
    }

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    let monthString = value.slice(0, 2);
    let month = parseInt(value.slice(0, 2), 10);

    let year = parseInt(value.slice(2, 5), 10);
    if (value.toString().length === 7) {
      year = parseInt(value.slice(5), 10);
    }

    if (monthString === "00" && month < 1 || month > 12) {
      return alert("Invalid month");
    }

    const currentYearLastTwoDigits = currentYear % 100;
    if (year < currentYearLastTwoDigits || (year === currentYearLastTwoDigits && month < currentMonth)) {
      return alert("Invalid Year");
    }

    this.setState(prevState => ({
      cardDetails: {
        ...prevState.cardDetails,
        [state]: value,
      },
    }));

    if (year.toString().length === 2 && month.toString().length === 2) {
      this.setState({ ExpiryDateTrue: true })
    }
    else {
      this.setState({ ExpiryDateTrue: false });
    }

  };




  handleCloseBillingAddress = () => { this.setState({ openDetailedBillingAddress: false }) };
  handleOpenBillingAddress = () => { this.setState({ openDetailedBillingAddress: true }) };
  handleBillingAddress = (e: any, stateName: string) => {
    const { value } = e.target;
    this.setState(prevState => ({
      BillingAddress: {
        ...prevState.BillingAddress,
        [stateName]: value,
      },
    }))
  };


  handleZipCodeForBillingAddress = (e: any, stateName: string) => {
    const { value } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      this.setState(prevState => ({
        BillingAddress: {
          ...prevState.BillingAddress,
          [stateName]: parseInt(value),
        },
      }))
    };
  };

  handleCardDetail = (e: any, stateName: string) => {
    const { value } = e.target;
    this.setState(prevState => ({
      cardDetails: {
        ...prevState.cardDetails,
        [stateName]: value
      }
    }));

  };

  handleCVV = (e: any, stateName: string) => {
    const { value } = e.target;
    if (/^\d{0,3}$/.test(value)) {
      this.setState(prevState => ({
        cardDetails: {
          ...prevState.cardDetails,
          [stateName]: value
        }
      }));
    }
  };
  useSameInformation = () => {
    this.setState({ useSameInformation: !this.state.useSameInformation }, () => {
      this.copyDataFromHomeDetails();
    });
  }

  copyDataFromHomeDetails = () => {
    if (this.state.useSameInformation) {
      this.setState({
        BillingAddress: {
          stateName: this.state.homeAddress.stateName,
          StreetAddress2: this.state.homeAddress.StreetAddress2,
          cityName: this.state.homeAddress.cityName,
          zipCode: this.state.homeAddress.zipCode,
          homeAddress: this.state.homeAddress.homeAddress,
        }
      })
    }
    else {
      this.setState({
        BillingAddress: {
          stateName: "",
          StreetAddress2: "",
          cityName: "",
          zipCode: 0,
          homeAddress: "",
        }
      })
    }
  };

  isHomeAddressComplete = () => {
    const { homeAddress } = this.state;
    const zipCodeValueLength = homeAddress.zipCode.toString().length === 6;

    return Object.values(homeAddress).every(value => value.toString().trim() !== '' && zipCodeValueLength);
  };

  isBillingAddressComplete = () => {
    const { BillingAddress, cardDetails } = this.state;
    const zipCodeValueLength = BillingAddress.zipCode.toString().length === 6;
    const SecurityCode = cardDetails.securityCode.toString().length === 3;

    return Object.values(BillingAddress).every(value => value.toString().trim() !== '') && Object.values(cardDetails).every(value => value.toString().trim() !== '' && zipCodeValueLength && SecurityCode && this.state.ExpiryDateTrue);
  };

  handleValueForBillingAddressZipCode = () => {
    const { BillingAddress } = this.state;
    if (BillingAddress.zipCode !== 0) {
      return parseInt(BillingAddress.zipCode.toString().trim().slice(0, 6))
    }
    else {
      return ""
    }
  };
  handleValueForHomeAddressZipCode = () => {
    const { homeAddress } = this.state;
    if (homeAddress.zipCode !== 0) { return parseInt(homeAddress.zipCode.toString().trim().slice(0, 6)) }
  };

  handleValueForCVV = () => {
    const { cardDetails } = this.state;
    if (cardDetails.securityCode !== 0) { return parseInt(cardDetails.securityCode.toString().trim().slice(0, 3)) }
  };

  handleCheckBox = () => { this.setState({ checkBox: !this.state.checkBox }) }
  // Customizable Area End
}
